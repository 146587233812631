<script>
import Layout from "../../layouts/horizontal"
import FileUpload from 'vue-upload-component'
//import Swal from "sweetalert2";
import http from '@/http'

export default {
  components: {
    Layout,
    FileUpload
  },
  data() {
    return {
      model:{
        type: 'upload-index-file',
        indexName: '',
        hasHeader: false,
        returnData: true,
        currency:'USD',
        dateFormat: '',
        fileContent: null
      },
      indexNameEnabled: true,
      dateOrder: 'MMDDYYYY',
      dateDivide: '/',
      dateFormatEnabled: false,
      sending: false,
      fileUploading: false,
      files: [],
      title: 'Upload Index File',
      breadcrumbs: [
        {
          text: 'Marketplace',
           to: { name: 'indices' }
        },
        {
          text: 'Upload Index File',
          active: true
        }
      ]
    };
  },
  created() {

  },
  watch: {

  },
  computed: {
    isValid(){
      var indexNameProvided; 
      if(this.indexNameEnabled){
        indexNameProvided = this.model.indexName;
      }
      else{
        indexNameProvided = this.model.indexName || this.model.hasHeader;
      }
      return indexNameProvided && this.model.fileContent;
    },
    uploadUrl(){
      return process.env.VUE_APP_BASE_URL + '/api/files/parse-timeseries-content';
    }
  },
  methods: {
    async send() {
      this.sending = true;
      await http.post( 'api/messages/send', this.model);
      this.sending = false;
      this.$store.dispatch('messages/loadActualStatus');
      this.$router.push({ name: "home" } );
    },
    
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
       
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }
        
        // Filter php html js file
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    },
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        // add
        newFile.headers["authorization"] = "Bearer " + localStorage.getItem('access_token');
        this.$refs.upload.active = true;
        this.fileUploading = true;
      }
      if (newFile && oldFile) {
        // update
        if(newFile.success) {
          this.$refs.upload.active = false;
          this.fileUploading = false;
          this.model.fileContent = newFile.response.fileContent;  
          this.model.hasHeader = newFile.response.hasHeader;
          this.indexNameEnabled = !this.model.hasHeader;
          this.model.indexName = "";
        }
      }
      if (!newFile && oldFile) {
        // remove
      }
    },
    getDateFormat(){
      var dateOrder = this.dateOrder;
      var dateDivide = this.dateDivide;
      var dateFormat = dateOrder[0];
      if(!this.dateFormatEnabled || dateOrder === "" || dateDivide === ""){
        return "Auto-Detect"
      }

      var ptr0, ptr1;
      for(let i = 1; i < dateOrder.length; i++){
        ptr0 = dateOrder[i-1];
        ptr1 = dateOrder[i];

        if(ptr1 != ptr0){
          dateFormat += dateDivide;
        }
        dateFormat += ptr1;
      }
      
      return dateFormat;
    },
    GetIndexNamePlaceholder(){
      if(this.indexNameEnabled){
        return "Enter Index Name";
      }
      else if(this.model.hasHeader){
        return "Using header row to name index/indices";
      }
      else{
        return "Check the box to name index/indices";
      }
    }
  }
};
</script>

<template>
  <Layout>
    <b-modal id="index-name-modal" centered ok-only hide-header>
      The Upload Custom Index feature allows you to upload
      one or multiple indices. You can also provide a header row
      that will be interpreted as the names of the indices. If a
      header row is not given an index name must be provided.
    </b-modal>
    
    <b-modal id="file-upload-modal" centered ok-only hide-header>
      The selected file must be a .CSV file with 1 Date column 
      and 1 or more Index Value (Return Data or NAV) columns:

      <div class="help-block text-muted ml-2 small">  
        <p class="ml-4">
          <b>Single Entry:</b><br/>
          <i>&lt;dates&gt;, &lt;Index name&gt;</i><br/>
          &lt;date 1&gt;, &lt;Index value&gt;<br/>
          &lt;date 2&gt;, &lt;Index value&gt;<br/>
          &lt;date 3&gt;, &lt;Index value&gt;<br/>
        </p>
        <p class="ml-4">
          <b>Basket Entry:</b><br/>	 
          <i>&lt;dates&gt;, &lt;Index1 name&gt;, &lt;Index2 name&gt;,…</i><br/>
          &lt;date 1&gt;,  &lt;Index1 value&gt;, &lt;Index2 value&gt;,…<br/>
          &lt;date 2&gt;,  &lt;Index1 value&gt;, &lt;Index2 value&gt;,…<br/>
          &lt;date 3&gt;,  &lt;Index1 value&gt;, &lt;Index2 value&gt;,…<br/>
          etc.
          </p>
      </div>

      A row of index names in the file are optional, but an Index Name must 
      be provided if they are not given. A row can be added or removed in
      the file preview if desired.
    </b-modal>

    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0">
            <span  class="title-content">
              {{ title }}
            </span>
          </h4>

          <div class="page-title-right">
            <b-breadcrumb :items="breadcrumbs" class="m-0"></b-breadcrumb>
          </div>
        </div>
      </div>
    </div>
    <div class="card cardc8">
      <div class="card-content">
        <div class="card-body">
          <div class="form-horizontal">
            <div class="container overflow-hidden px-0">
              <div class="form-group my-4">
                <label>
                    File
                    <div class="btn ml-2" @click="$bvModal.show('file-upload-modal')" v-b-tooltip.hover.html="'Click to learn more'" triggers="hover click">
                      <i class="mdi mdi-information-variant" style="background-color:#eff2f7;border-radius:50%"/>
                    </div>
                </label>
                <div class="">
                  <i class="fa fa-spin fa-spinner" v-if="fileUploading"></i>
                  <div v-if="model.fileContent" class="mb-1">
                    <textarea class="form-control" rows="10" v-model="model.fileContent">

                    </textarea>
                  </div>
                  <div v-show="!fileUploading" v-if="!model.fileContent">
                    <div class="d-inline-block p-5" style="border:1px dashed gray;color:gray;border-radius:7px">
                      <label>Drop files anywhere to upload<br/>or</label>
                      <file-upload
                        class="btn btn-secondary btn-block"
                        :post-action="uploadUrl"
                        accept=".xlsx,text/*"
                        :multiple="false"
                        :size="1024 * 1024 * 20"
                        :drop="true"
                        v-model="files"
                        @input-filter="inputFilter"
                        @input-file="inputFile"
                        ref="upload">
                        <i class="fa fa-upload"></i>
                        Select File
                      </file-upload>
                    </div>
                  </div>
                  <div v-show="model.fileContent">
                    <div class="d-inline-block">
                      <file-upload
                        class="btn btn-secondary btn-block"
                        :post-action="uploadUrl"
                        accept=".xlsx,text/*"
                        :multiple="false"
                        :size="1024 * 1024 * 20"
                        :drop="true"
                        v-model="files"
                        @input-filter="inputFilter"
                        @input-file="inputFile"
                        ref="upload">
                        <i class="fa fa-upload"></i>
                        Select File
                      </file-upload>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row mb-4 g-5">
                <div class="form-group col-md-7">
                  <input 
                    type="checkbox" 
                    class="mr-2" 
                    v-model="indexNameEnabled"
                    v-if="model.hasHeader"
                    @click="model.indexName=''"
                  /> 
                  <label>
                    <span v-if="model.hasHeader">Override</span> Index Name <span v-if="model.hasHeader">(optional)</span>
                    <div class="btn ml-2" @click="$bvModal.show('index-name-modal')" v-b-tooltip.hover.html="'Click to learn more'" triggers="hover click">
                      <i class="mdi mdi-information-variant" style="background-color:#eff2f7;border-radius:50%"/>
                    </div>
                  </label>
                  <input v-if="indexNameEnabled" class="form-control" v-model="model.indexName" :placeholder="GetIndexNamePlaceholder()"/>
                  <input v-if="!indexNameEnabled" class="form-control" :placeholder="GetIndexNamePlaceholder()" disabled/>
                </div>
              </div>

              <div class="form-row my-4 g-5">
                <div class="form-group col-md-6">
                  <label>
                    Data Type
                  </label>
                  <b-form-radio-group v-model="model.returnData">
                    <b-form-radio class="mr-5" v-model="model.returnData" :value="true">Return Data</b-form-radio>
                    <b-form-radio v-model="model.returnData" :value="false">NAV</b-form-radio>
                  </b-form-radio-group>
                </div>
              </div>

              <div class="form-row my-4 g-5">
                <div class="form-group col-md-6">
                  <label>
                    Currency
                  </label>
                  <div class="form-row">
                    <div class="col-md-3">
                      <select class="form-control" v-model="model.currency">
                        <option value='USD'>USD</option>
                        <option value='CNY'>CNY</option>
                        <option value='EUR'>EUR</option>
                        <option value='USD'>SEK</option>
                      </select>
                    </div>  
                  </div>
                </div>
              </div>

              <div class="form-row my-4 g-5">
                <div class="form-group col-md-6">
                  <input 
                    type="checkbox" 
                    class="mr-2" 
                    v-model="dateFormatEnabled"
                  /> 
                  <label>
                    Date Format (optional)
                  </label>
                  <div class="form-row">
                    <div class="col-md-3">
                      <select 
                        class="form-control"
                        :disabled="!dateFormatEnabled"
                        v-model="dateOrder"
                      >
                        <option value='MMDDYYYY'>MDY</option>
                        <option value='DDMMYYYY'>DMY</option>
                        <option value='YYYYMMDD'>YMD</option>
                        <option value='MMYYYYDD'>MYD</option>
                        <option value='DDYYYYMM'>DYM</option>
                        <option value='YYYYDDMM'>YDM</option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <select 
                        class="form-control"
                        :disabled="!dateFormatEnabled"
                        v-model="dateDivide"
                      >
                        <option value='/'>/</option>
                        <option value='-'>-</option>
                        <option value='.'>.</option>
                        <option value=','>,</option>
                        <option value=' '>-Space-</option>
                      </select>
                    </div>
                    <div class="col-md-6 pl-2 my-auto">
                        : <b>{{ getDateFormat() }}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-md-6">
              <a href="/" class="py-1 mr-4">
                <i class="fa fa-angle-double-left mr-1"></i> Cancel
              </a>
            </div>
            <div class="col-md-6 text-right">
              <button type="button" class="btn py-1 btn-primary" :disabled="!isValid || sending" @click="send">
                <i class="fa fa-save mr-2" v-if="!sending"/>
                <i class="fa fa-spin fa-spinner" v-if="sending"/>
                Save 
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>